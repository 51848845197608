import store from '@/store'

// 获取当前时间，格式YYYY-MM-DD hh:mm:ss
export function getNowFormatTime (date) {
  // var date = new Date();
  var seperator1 = '-'
  var year = date.getFullYear()
  var month = date.getMonth() + 1
  var strDate = date.getDate()

  var hour = date.getHours()
  var minute = date.getMinutes()
  var second = date.getSeconds()

  if (month >= 1 && month <= 9) {
    month = '0' + month
  }
  if (strDate >= 0 && strDate <= 9) {
    strDate = '0' + strDate
  }
  if (hour >= 0 && hour <= 9) {
    hour = '0' + hour
  }
  if (minute >= 0 && minute <= 9) {
    minute = '0' + minute
  }
  if (second >= 0 && second <= 9) {
    second = '0' + second
  }
  var currentdate = year + seperator1 + month + seperator1 + strDate + ' ' + hour + ':' + minute + ':' + second
  return currentdate
}

// 获取当前时间，格式YYYY-MM-DD
export function getNowFormatDate (date) {
  // var date = new Date();
  var seperator1 = '-'
  var year = date.getFullYear()
  var month = date.getMonth() + 1
  var strDate = date.getDate()

  if (month >= 1 && month <= 9) {
    month = '0' + month
  }
  if (strDate >= 0 && strDate <= 9) {
    strDate = '0' + strDate
  }

  var currentdate = year + seperator1 + month + seperator1 + strDate
  return currentdate
}

export function getContractStatuses (key) {
  let contractStatuses = store.state.Ylt.contractStatuses
  let m = new Map()
  contractStatuses.forEach(item => {
    m.set(item.code, item.value)
  })
  return m.get(key)
}

export function getContractTypes (key) {
  let contractTypes = store.state.Ylt.contractTypes
  let m = new Map()
  contractTypes.forEach(item => {
    m.set(item.code, item.value)
  })
  return m.get(key)
}

export function getOrderTypes (key) {
  let orderTypes = store.state.Ylt.orderTypes
  let m = new Map()
  orderTypes.forEach(item => {
    m.set(item.code, item.value)
  })
  return m.get(key)
}
